export default class Grid {
    constructor(_container=null) {
        this.grid = null
        this.container = _container
        window.SIZE.on("resize", () => this.resize())
        this.resize()
    }

    reload() {
        this.container = document.querySelector('.grid-wrap')
        this.grid = this.setup()
        console.log("Reload", this.grid)
    }

    resize() {
        this.updateBlockSize()
        if (!this.grid) return false
        this.grid.layout()
        gsap.delayedCall(.5, () => {
            this.grid.layout()
        })
    }

    updateBlockSize() {
        if (!this.container) return false
        this.container.style.setProperty('--grid-block-size', `${this.size}px`)
    }
//
    setup() {
        if (!this.container) return null
        if (BROWSER.mobile) {
            gsap.to(".grid-wrap", {
                delay: .1,
                duration: .2,
                opacity: 1
            })
            gsap.set(".grid-item, .grid-wrap img, .grid-wrap video", {
                height: "auto"
            })
        } else {
            this.updateBlockSize()
            const _packery = new Packery(this.container, {
                itemSelector: '.grid-item',
                // columnWidth: this.size,
                transitionDuration: 0,
                gutter: 0,
                resize: true
            })
            gsap.to(".grid-wrap", {
                delay: .1,
                duration: .2,
                opacity: 1
            })
            return _packery
        }
    }

    get size() {
        const grid = document.querySelector(".grid-wrap")
        const _width = grid.getBoundingClientRect().width
        if (BROWSER.mobile) return _width
        else return (_width / 4).toFixed(6)
    }

    destroy() {
        if (this.grid) this.grid.destroy()
        this.grid = null
        this.container = null
    }
}